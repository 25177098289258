import React, { createContext, useEffect, useState } from "react";

export const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const getUser = () => {
    let currentUserLocal = localStorage.getItem("user");
    try {
      if (currentUserLocal) {
        currentUserLocal = JSON.parse(currentUserLocal);
      }
    } catch (error) {
      currentUserLocal = null;
    }
    if (currentUserLocal) {
      setUser(currentUserLocal);
    }

    return user;
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, getUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
